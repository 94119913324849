.card {
    width: 100%;

}

.text {
    position: absolute; 
    width: 90%;
    z-index: 2;
    margin: 7%;
    font-size: 350%;
    line-height: 150%;
    font-weight: 600;
}
