.list {
    list-style-type: none;
    display: flex;
    padding: 0;
    text-align: center;
    flex-wrap: wrap;

}

.listContainer {
    width: auto;
    margin: 0 auto;
    display: inline-block;
}

.selected {
    position: absolute;
    background: #222;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    /*
     Put top edge and left edge in the center
    */
    top: 50%;
    left: 50%;
    margin: -4px 0px 0px -4px;
}

.box {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #222;
    cursor: pointer;
}

.option {
    margin: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.option button {
    margin: 0.5rem;
    width: 20px;
    position: relative;
    height: 20px;
    border-radius: 20px;
    border: solid 3px #444;
    cursor: pointer;
}