.download {
    background-color: #f93628;
    color: #fff;
    margin: 0 auto;
    display: block;
    font-size: 1.5rem;
    padding: 0.7rem 1.4rem;
    border: 0;
    border-radius: 6px;   
    margin-bottom: 1rem;
    cursor: pointer;
    font-weight: 600;
}

.download i {
    margin-right: 2.5rem;
}


@media screen and (max-width: 450px) {
    .buttonContainer { 
        flex-direction: column;
    }
}

.buttonContainer { 
    display: flex;
    margin-bottom: 1rem; 
}

.share {
    background-color: #1DA1F2;
    color: #fff;
    margin: 0 auto;
    display: inline-block;
    font-size: 1.5rem;
    padding: 0.7rem 1.4rem;
    border: 0;
    border-radius: 6px;   
    margin-bottom: 2rem; 
    cursor: pointer;
    font-weight: 600;
    margin: 0 auto;
    text-decoration: none;
}

.share i {
    margin-right: 2.5rem;
}

.subheader {
    background-image: url(./images/subheader-background.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    padding: 1rem;
    margin: 0.5rem auto 1rem;
    text-transform: uppercase;
    color: #595a5b;
    font-size: 1.1em;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.2rem;
    min-width: 450px;
    width: 60%;
}

@media screen and (max-width: 450px) {

    .subheader {
        width: 100%;
        max-width: 100%;
        font-size: 0.9em;
        min-width: 100%;
    }
}

.header {
    line-height: 1em;
}

.error { 
    border: solid #f93628;
    background-color: #eee;
    min-width: 400px;
    width: 70%;
    margin: 0.5rem auto 2rem;
    padding: 1rem;
    border-radius: 10px;
}