.form {
    display: flex;
    flex-direction: column;
}

.input {
    border-radius: 4px;
    width: 100%;
    font-size: 1.4rem;
    padding: 1rem;
    margin: 1rem 0;
    border-style: solid;
}