*, :after, :before {
    box-sizing: border-box;
}

.container {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    background: url(../images/tile_stripes-light.png) repeat;
}

.header {
    background-color: rgb(81, 82, 83);
    width: 100%;
    height: 72px;
    padding: 0;
    margin: 0;
}

.headerContainer {
    width: 100%;
    height: 72px;
    max-width: 800px;
    margin: 0px auto;
}

.logo {
    width: 120px;
    position: relative;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    margin-top: 10px;
    margin-left: 1rem;
    overflow: hidden;
    vertical-align: middle;
}

.main {
    min-height: 100vh;
}

.footer {
    background-color: rgb(45, 45, 45);
    width: 100%;
}

.footerContainer {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 1rem 2.5rem;
}

.content {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

@media screen and (max-width: 820px) {
    .content {
        padding: 0 1rem;
    }
}

.footerContainer a:hover {
    opacity: 1.0;
}
.footerContainer a {
    opacity: 0.65;
    transition: opacity 0.5s ease 0s;
    width: 200px;
    float: left;
    margin-right: 24px;
}

.footerIntro {
    font-size: 0.5em;
    margin: 0;
    color: rgb(136, 136, 136);
    text-transform: uppercase;
    font-weight: normal;
}

.footerSupporters {
    margin-top: 24px;
    display: flex
}

.supporter {
    opacity: 0.9;
    width: 100%;
}

body {
    margin: 0px;
    font-family: Avenir;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
    display: block;
}
body, html {
    height: 100%;
    position: relative;
}
body p { 
    font-size: 1.2rem;
}