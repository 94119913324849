.control {
    display: grid;
    width: 100%;
    grid-template-columns: 15% 70% 15%;
    align-items: center;
    min-height: 12rem;
}

.arrow {
    font-size: 2em;
    cursor: pointer;
    color: #444;
    text-align: center;
}

@media screen and (max-width: 450px) {
    
}

.current {
    cursor: pointer;
}