.card {
    width: 100%;
    min-height: 400px;
    background-color: #fff;
    position: relative;
    display: flex;
    padding: 1rem;
}

.message {
    width: 55%;
    height: 380px;
    border: none;
    overflow: auto;
    outline: none;

    font-size: 1.1em;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
}

.divider {
    width: 5px;
    margin: 0 1rem;
    background-color: #222;
}